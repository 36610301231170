exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-5-steps-to-grow-a-restaurant-js": () => import("./../../../src/pages/article/5-steps-to-grow-a-restaurant.js" /* webpackChunkName: "component---src-pages-article-5-steps-to-grow-a-restaurant-js" */),
  "component---src-pages-article-5-things-to-consider-when-creating-digital-menus-js": () => import("./../../../src/pages/article/5-things-to-consider-when-creating-digital-menus.js" /* webpackChunkName: "component---src-pages-article-5-things-to-consider-when-creating-digital-menus-js" */),
  "component---src-pages-article-checklist-to-open-a-restaurant-js": () => import("./../../../src/pages/article/checklist-to-open-a-restaurant.js" /* webpackChunkName: "component---src-pages-article-checklist-to-open-a-restaurant-js" */),
  "component---src-pages-article-hotel-ciputra-jakarta-enhances-guest-experience-with-seamless-ordering-solution-js": () => import("./../../../src/pages/article/hotel-ciputra-jakarta-enhances-guest-experience-with-seamless-ordering-solution.js" /* webpackChunkName: "component---src-pages-article-hotel-ciputra-jakarta-enhances-guest-experience-with-seamless-ordering-solution-js" */),
  "component---src-pages-article-how-to-cope-with-inflation-part-1-js": () => import("./../../../src/pages/article/how-to-cope-with-inflation-part-1.js" /* webpackChunkName: "component---src-pages-article-how-to-cope-with-inflation-part-1-js" */),
  "component---src-pages-article-how-to-market-your-restaurant-js": () => import("./../../../src/pages/article/how-to-market-your-restaurant.js" /* webpackChunkName: "component---src-pages-article-how-to-market-your-restaurant-js" */),
  "component---src-pages-article-index-js": () => import("./../../../src/pages/article/index.js" /* webpackChunkName: "component---src-pages-article-index-js" */),
  "component---src-pages-article-qr-code-vs-pdf-menu-js": () => import("./../../../src/pages/article/qr-code-vs-pdf-menu.js" /* webpackChunkName: "component---src-pages-article-qr-code-vs-pdf-menu-js" */),
  "component---src-pages-article-should-my-restaurant-use-digital-menus-js": () => import("./../../../src/pages/article/should-my-restaurant-use-digital-menus.js" /* webpackChunkName: "component---src-pages-article-should-my-restaurant-use-digital-menus-js" */),
  "component---src-pages-article-what-makes-a-great-restaurant-js": () => import("./../../../src/pages/article/what-makes-a-great-restaurant.js" /* webpackChunkName: "component---src-pages-article-what-makes-a-great-restaurant-js" */),
  "component---src-pages-article-what-to-include-on-your-restaurant-website-js": () => import("./../../../src/pages/article/what-to-include-on-your-restaurant-website.js" /* webpackChunkName: "component---src-pages-article-what-to-include-on-your-restaurant-website-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-demo-digital-menu-js": () => import("./../../../src/pages/live-demo/digital-menu.js" /* webpackChunkName: "component---src-pages-live-demo-digital-menu-js" */),
  "component---src-pages-live-demo-reservations-js": () => import("./../../../src/pages/live-demo/reservations.js" /* webpackChunkName: "component---src-pages-live-demo-reservations-js" */),
  "component---src-pages-live-demo-surveys-js": () => import("./../../../src/pages/live-demo/surveys.js" /* webpackChunkName: "component---src-pages-live-demo-surveys-js" */),
  "component---src-pages-live-demo-website-js": () => import("./../../../src/pages/live-demo/website.js" /* webpackChunkName: "component---src-pages-live-demo-website-js" */),
  "component---src-pages-popular-menus-js": () => import("./../../../src/pages/popular-menus.js" /* webpackChunkName: "component---src-pages-popular-menus-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-solutions-digital-menu-js": () => import("./../../../src/pages/solutions/digital-menu.js" /* webpackChunkName: "component---src-pages-solutions-digital-menu-js" */),
  "component---src-pages-solutions-reservations-js": () => import("./../../../src/pages/solutions/reservations.js" /* webpackChunkName: "component---src-pages-solutions-reservations-js" */),
  "component---src-pages-solutions-surveys-js": () => import("./../../../src/pages/solutions/surveys.js" /* webpackChunkName: "component---src-pages-solutions-surveys-js" */),
  "component---src-pages-solutions-website-js": () => import("./../../../src/pages/solutions/website.js" /* webpackChunkName: "component---src-pages-solutions-website-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-tools-menu-builder-js": () => import("./../../../src/pages/tools/menu-builder.js" /* webpackChunkName: "component---src-pages-tools-menu-builder-js" */)
}

